const buildLinkNames = (pages) => {
    const linkNames = {};
    pages.forEach(page => {
        linkNames[`about/${page.path}`] = {
            title: page.title,
            thumbnail: page.thumb.url
        };
    })
    return linkNames;
};

export {
    buildLinkNames
}
