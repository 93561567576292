import {Heading, Box, LinkBox} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom";

export function Card({pageInfo, to}) {
    return (
        <LinkBox
            as={RouterLink}
            to={to}
            flex={1}
            minW={{base:220, md: 300, lg: 320}}
            h={{base:250, md: 340, lg: 360}}
            mr={4}
            mb={4}
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            overflow="hidden"
            title={pageInfo.title}
        >
            <Box
                backgroundImage={pageInfo.thumbnail}
                backgroundPosition="center center"
                backgroundSize="cover"
                borderRadius={2}
                flex={1}
            />

            <Box py={4}>
                <Heading fontWeight={400} >
                    {pageInfo.title}
                </Heading>
            </Box>

        </LinkBox>
    );
}
