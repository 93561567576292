import {Flex, Image, useColorModeValue, Link} from '@chakra-ui/react';
import {ColorModeSwitcher} from '../ColorModeSwitcher/component';
import { Link as RouterLink } from 'react-router-dom';
import logoLight from '../../../../assets/logo-light.svg';
import logoDark from '../../../../assets/logo-dark.svg';

export default function HeaderBar() {
    const logo = useColorModeValue(logoDark, logoLight)

    return (
        <Flex p={5} alignItems={{base:'flex-end', lg: 'center'}} justifyContent='space-between' fontSize={{base:'l', md: 'xl'}}>
            <Link as={RouterLink}  to='/' title='Back to Index'>
                <Image src={logo} height={10} alt='Riverside Church Logo' title='Riverside Church Logo' />
            </Link>
            <ColorModeSwitcher/>
        </Flex>
    );
}