import React from "react";
import {Heading, Link, Text, Image} from "@chakra-ui/react";

export const markdownTheme = (currentColor) => ({
    p:  props => (<Text size='lg' mb={5}>{props.children}</Text>),
    img:  props => (<Image src={props.src} float="right" ml={5} my={2} />),
    h1: props => (<Heading as='h1' mt={20} mb={6} fontWeight={500} size='2xl'>{props.children}</Heading>),
    h2: props => (<Heading as='h2' mt={8} mb={3} fontWeight={500} size='xl'>{props.children}</Heading>),
    h3: props => (<Heading as='h3' mt={2} mb={2} fontWeight={500} size='lg'>{props.children}</Heading>),
    a: props => (<Link href={props.href} target='_blank' color={currentColor} fontWeight={500}>{props.children}</Link>),
    blockquote: props => (
        <Text m={10} pr={4} ml={0} pl={6} borderLeftColor={currentColor} borderLeftWidth={2}>
            {props.children}
        </Text>
    )
})
