import {useLocation, Navigate} from "react-router-dom";
import gql from 'graphql-tag'
import {useQuery} from "@apollo/client";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import Banner from "../../ui/Banner/component";
import Loading from "../../ui/Loading/component";
import {markdownTheme} from "../../../helpers/markdownHelper";
import {Box, useColorModeValue} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";

const PAGE_QUERY = (path) => gql`
  {
      page(where: {path: "${path}"}) {
        path
        title
        bannerText
        body {
          markdown
        }
        thumb {
          fileName
          url(
            transformation: {validateOptions: false, image: {resize: {}}, document: {output: {format: jpg}}}
          )
        }
      }
  }
`;

export default function CMSPage() {

    const color = useColorModeValue('#007fd5', '#40a2e5');

    const location = useLocation();
    const path = location.pathname.split('/').pop();
    ReactGA.pageview(location.pathname);

    const { data } = useQuery(PAGE_QUERY(path));

    if(!data){
        return( <Loading /> );
    } else if(data.page === null){
        return( <Navigate to="/" /> );
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{data.page.title}</title>
                    <link rel="canonical" href={`https://www.riversideburton.com${location.pathname}`}/>
                    <meta name="description" content={data.page.bannerText}/>
                </Helmet>
                <Banner>
                    {data.page.bannerText}
                </Banner>
                <Box mb={50}>
                    <ReactMarkdown
                        components={ChakraUIRenderer(markdownTheme(color))}
                        children={data.page.body.markdown}
                    />
                </Box>
            </>
        );
    }
}