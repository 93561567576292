import {Box, Heading} from "@chakra-ui/react";

function Banner({children}) {
    return (
        <Box
            as="header"
            my={{base:'50px', lg: '100px'}}
            mx={{base:0, lg: 20, xl: 40}}
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            <section>
                <Heading as='em' fontStyle='normal' fontWeight={400} fontSize={{base: '4xl', lg: '5xl'}}>{children}</Heading>
            </section>
        </Box>
    );
}

export default Banner;
