import React from 'react';
import "@fontsource/spartan/700.css"
import "@fontsource/spartan/500.css"
import "@fontsource/spartan/300.css"
import ReactGA from 'react-ga';
import theme from "./theme";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import {ChakraProvider, Box} from "@chakra-ui/react";
import LandingPage from "./js/components/pages/LandingPage/component";
import CMSPage from "./js/components/pages/CMSPage/component";
import HeaderBar from "./js/components/ui/HeaderBar/component";
import FooterBar from "./js/components/ui/FooterBar/component";
import {Helmet} from "react-helmet";
import {pageTitle, pageDescription} from "./js/helpers/bodyLibrary";

ReactGA.initialize('UA-218925705-2',{gaOptions: {
        cookieDomain: 'riversideburton.com',
        cookieFlags: 'SameSite=None; Secure',
        storeGac: false,
    }});
ReactGA.set('allowAdFeatures',false);
ReactGA.set('anonymizeIp',false);
ReactGA.set('forceSSL',false);

function App() {
    return (
      <Router>
          <ChakraProvider theme={theme}>
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>{pageTitle}</title>
                  <link rel="canonical" href="https://www.riversideburton.com/" />
                  <meta name="description" content={pageDescription} />
              </Helmet>
              <Box minHeight="100vh" display="flex" flexDir="column" justifyContent="space-between">
              <HeaderBar />
              <Box px={{base:5, md: 10, lg: 20}} py={{base:5, md: 10}}>
                  <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route exact path="/about/:id" element={<CMSPage />} />
                  </Routes>
              </Box>
              <FooterBar />
              </Box>
          </ChakraProvider>
      </Router>
  );
}

export default App;
