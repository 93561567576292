import { createIcon } from "@chakra-ui/react";

export const FacebookIcon = createIcon({
    displayName: 'Facebook Icon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fill='currentColor'
            d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.323-.593 1.323-1.325V1.325C24 .593 23.407 0 22.675 0z"
        />
    )
})

export const YouTubeIcon = createIcon({
    displayName: 'YouTube Icon',
    viewBox: '0 0 24 24',
    path: (
        <path
            fill='currentColor'
            d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z"
        />
    )
})