import {Flex, Link, Box, Text} from '@chakra-ui/react';
import {FacebookIcon, YouTubeIcon} from "../../../helpers/customIcons";
import {ExternalLinkIcon} from "@chakra-ui/icons";

export default function HeaderBar() {

    return (
        <Flex flexDir={{base:'column', md: 'row'}} px={5} pt={5} alignItems="flex-start" justifyContent="space-between" fontSize={{base:'l', md: 'xl'}}>
            <Box mb={5}>
                <Text fontSize="sm" fontWeight={400}>
                    <Link title="Riverside Church on Google Maps" href='https://goo.gl/maps/osJm5mgDFmJKDozB8' isExternal>
                        81 High St<ExternalLinkIcon ml={2} mb={2} />
                    </Link>
                </Text>
                <Text fontSize="sm" fontWeight={400}>Burton-on-Trent</Text>
                <Text fontSize="sm" fontWeight={400}>DE14 1LD</Text>
            </Box>
            <Box mb={5}>
                <Text fontSize="sm" fontWeight={400}>Email: <Link title="Contact us by email" href="mailto:hello@riversideburton.com">hello@riversideburton.com</Link></Text>
                <Text fontSize="sm" fontWeight={400}>Tel: <Link title="Contact us by telephone" href="tel:+441283535999">01283 535999</Link></Text>
                <Text fontSize="sm" fontWeight={400}>Registered charity: 515335</Text>
            </Box>
            <Box alignSelf="flex-end" mb={5}>
                <Link title="Facebook Page" href='https://www.facebook.com/riversideburton' isExternal>
                    <FacebookIcon w={8} h={8} />
                </Link>
                <Link title="YouTube Channel" href='https://www.youtube.com/channel/UCqAyfxyV5OyjI0Amps5K5wg' isExternal ml={5}>
                    <YouTubeIcon w={8} h={8} />
                </Link>
            </Box>

        </Flex>
    );
}