import Banner from "../../ui/Banner/component";
import NavLink from "../../ui/PageNav/component";
import gql from 'graphql-tag'
import ReactGA from "react-ga";
import {useQuery} from "@apollo/client";
import {Box, Heading} from "@chakra-ui/react";
import Loading from "../../ui/Loading/component";
import {Helmet} from "react-helmet";
import {pageDescription, pageTitle} from "../../../helpers/bodyLibrary";
import React from "react";


const PAGES_QUERY = gql`
  {
      pages(orderBy: order_ASC) {
        path
        title
        thumb {
          fileName
          url(
            transformation: {validateOptions: false, image: {resize: {}}, document: {output: {format: jpg}}}
          )
        }
      }
  }
`;

function LandingPage() {

    const { loading, error, data } = useQuery(PAGES_QUERY);

    ReactGA.pageview('/');

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
                <link rel="canonical" href="https://www.riversideburton.com/" />
                <meta name="description" content={pageDescription} />
            </Helmet>
            <Banner>
                Riverside is a family-friendly church in the middle of Burton, with a ❤️ for being Jesus in our
                community.
            </Banner>
            <Heading as='h1' fontWeight="400" mb={4}>Find out more</Heading>
            <Box as="nav" flexWrap="wrap" display="flex" justifyContent={{base:'center', lg: 'flex-start'}} width="100%">

                {
                    loading && <Loading />
                }
                {
                    error && <Loading />
                }
                {
                    data && <NavLink data={data}/>
                }
            </Box>
        </>

    );

}

export default LandingPage;
