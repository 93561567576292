import { buildLinkNames } from "../../../helpers/navigation.helper";
import {Card} from "../Card/component";

export default function PageNav({data}) {

    const linkNames = buildLinkNames(data.pages);
    return (
            <>
                {
                    Object.keys(linkNames).map((key) => (
                        <Card pageInfo={linkNames[key]} key={key} to={key} />
                    ))
                }
            </>
    );
}