import { extendTheme } from "@chakra-ui/react"
import { mode } from '@chakra-ui/theme-tools'

const Menu = {
  baseStyle: {
    list: {
      minW: '150px'
    },
  }
}
const Heading = {
  sizes: {
    '2xl': {
      fontSize: ["1.5em","2em","2.3em"],
    },
    'xl': {
      fontSize: "1.4em",
    },
    'lg': {
      fontSize: "1.3em",
    }
  }
}
const Text = {
  baseStyle: {
    fontWeight: 400,
  }
}
const theme = extendTheme({
  components: {
    Menu,
    Heading,
    Text
  },
  fonts: {
    heading: "Spartan",
    body: "Spartan",
  },
  config: {
    useSystemColorMode: true,
  },
  styles: {
    global: (props) => ({
      body: {
        backgroundColor: mode('#fff', '#101010')(props)
      },
    }),
  }
})

export default theme
